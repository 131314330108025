<template>
  <section>
    <TitleBar>
      Certificats Bio
      <template #toolbar-content>
        <HelpCertificatComponent></HelpCertificatComponent>
        &nbsp;
        <b-button pill variant="primary" @click="nouveauCertificat">
          <b-icon-file-earmark-plus></b-icon-file-earmark-plus>
          Ajouter
        </b-button>
      </template>
    </TitleBar>
    <b-container fluid>
      <div v-if="loading" class="text-center text-warning my-3">
        <b-spinner class="align-middle"></b-spinner>
        <br />
        <strong>Chargement en cours...</strong>
      </div>
      <template v-else>
        <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
        <StrapiEditoComponent></StrapiEditoComponent>
        <PaginatedTable
          :fields="fields"
          :busy="busy"
          :pageable="pageable"
          :page="page"
          @change="pageableChanged"
        >
          <template #mobile-card="{ item }">
            <CertificatCard :item="item"></CertificatCard>
          </template>
          <template #cell(dateHeureDepot)="{ item }">
            <DateTimeComponent :value="item.dateHeureDepot"></DateTimeComponent>
          </template>
          <template #cell(commentaire)="{ item }">
            <span class="commentaire">{{ item.commentaire }}</span>
          </template>
          <template #cell(fichier)="{ item }">
            <DownloadComponent
              :typeDocument="typeDocument"
              :targetId="item.idCertificat"
            ></DownloadComponent>
          </template>
        </PaginatedTable>
        <FileUploadDialog ref="fileUploadDialog" title="Nouveau certificat Bio">
          <template #bottom>
            <em>
              Votre certificat sera transmis automatiquement au service
              logistique de votre coop&eacute;rative.
            </em>
          </template>
        </FileUploadDialog>
      </template>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpCertificatComponent from "../../components/controls/HelpCertificatComponent.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import CertificatCard from "../../components/cards/CertificatCard.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import FileUploadDialog from "../../components/dialogs/FileUploadDialog.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "CertificatsView",
  components: {
    TitleBar,
    HelpCertificatComponent,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
    CertificatCard,
    DateTimeComponent,
    DownloadComponent,
    FileUploadDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.CERTIFICATS),
      typeDocument: this.$store.state.expl.typesDocument.CERTIFICAT,
      loading: false,
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let response = await ExploitationService.getCertificats(
          this.exploitationCourante.codeTiers,
          this.getSearchPayload()
        );
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async nouveauCertificat() {
      let upload = await this.$refs.fileUploadDialog.show();
      if (!upload?.fichier) {
        return;
      }

      let formData = new FormData();
      formData.append("fichier", upload.fichier, upload.fichier?.name);
      formData.append("commentaire", upload.commentaire);

      this.error = null;
      try {
        this.loading = true;
        await ExploitationService.createCertificat(
          this.exploitationCourante.codeTiers,
          formData
        );
        this.load();
      } catch (error) {
        this.error = UtilsService.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
